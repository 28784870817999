:root {
  --primary: #fe005a;
  --secondary: #5762d5;
  --yellow: #ffca64;
  --green: #70e13a;
  --tint: #2fd7ee;
  --gray900: #0b0d19;
  --gray800: #121420;
  --gray700: #191b27;
  --gray600: #2d2f3b;
  --gray500: #41434f;
  --gray400: #5f616d;
  --gray300: #a5a7b3;
  --gray200: #e6e4f5;
  --gray100: #f2f0fc;
  --gray50: #f6f5fc;
  --white: #fff;
  --ff: Saira, Roboto, sans-serif;
  --transition: .3s ease-out;
  --fw-thin: 100;
  --fw-xlight: 200;
  --fw-light: 300;
  --fw-regular: 400;
  --fw-medium: 500;
  --fw-semi: 600;
  --fw-bold: 700;
}

html {
  box-sizing: border-box;
  font-size: 16px;
}

*, :before, :after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

img, svg {
  max-width: 100%;
  height: auto;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--gray900);
  color: var(--white);
  font-family: var(--ff);
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

input, button, select, textarea, optgroup, option {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
}

* {
  box-sizing: border-box;
}

.container {
  width: 90rem;
  max-width: 100vw;
  margin: 0 auto;
  padding: 0 2rem;
  position: relative;
}

@media screen and (width <= 991px) {
  .container {
    padding: 0 1rem;
    overflow: hidden;
  }
}

a {
  text-decoration: none;
}

h1, h2, h3 {
  font-weight: var(--fw-bold);
  text-transform: uppercase;
  line-height: 1;
}

h1, h2 {
  font-size: 4.5rem;
}

@media screen and (width <= 991px) {
  h1, h2 {
    font-size: 3.5rem;
  }
}

h3 {
  font-size: 2rem;
}

p {
  font-size: 1.25rem;
}

p.lead {
  font-size: 2rem;
  font-weight: var(--fw-semi);
  text-transform: uppercase;
  color: var(--yellow);
}

@media screen and (width <= 991px) {
  p.lead {
    font-size: 1.5rem;
  }
}

.topbar {
  z-index: 100;
  transition: var(--transition) background-color, var(--transition) padding, var(--transition) backdrop-filter;
  backdrop-filter: blur();
  width: 100vw;
  padding: 2rem 0;
  position: fixed;
  top: 0;
}

@media screen and (width <= 991px) {
  .topbar svg {
    max-height: 3rem;
  }
}

.topbar__content {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.topbar__buttons {
  gap: 1rem;
  display: flex;
}

@media screen and (width <= 991px) {
  .topbar__buttons {
    display: none;
  }
}

.topbar--sticky {
  backdrop-filter: blur(1rem);
  background-color: #00000026;
  padding: 1rem 0;
}

.hero {
  align-items: center;
  height: calc(100vh - 10rem);
  display: flex;
}

@media screen and (width <= 991px) {
  .hero {
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    padding: 6rem 0 2rem;
  }
}

.hero__content {
  flex-direction: column;
  flex: 0 auto;
  justify-content: center;
  max-width: 50rem;
  display: flex;
}

.hero__description {
  color: var(--gray50);
  margin-top: 2rem;
}

.hero__buttons {
  align-items: center;
  gap: 1rem;
  margin-top: 4rem;
  display: flex;
}

@media screen and (width <= 991px) {
  .hero__buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .hero__buttons .button--outline {
    display: none;
  }
}

.hero__image {
  aspect-ratio: 1;
  padding: 5rem;
  transition: scale .5s ease-in-out;
  position: relative;
}

@media screen and (width <= 991px) {
  .hero__image {
    display: none;
  }
}

.hero__image img {
  z-index: 1;
  transition: margin .5s ease-in-out, scale .5s ease-in-out;
  animation-name: starAnimate;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: .75s;
  position: relative;
}

.hero__image:before, .hero__image:after {
  content: " ";
  opacity: .9;
  background-image: url("torus.d18b4128.png");
  background-repeat: no-repeat;
  background-size: 200% 100%;
  width: 50%;
  height: 100%;
  transition: margin .5s ease-in-out;
  animation-name: torusAnimate;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  display: block;
  position: absolute;
  inset: 0;
}

.hero__image:before {
  z-index: 0;
}

.hero__image:after {
  z-index: 2;
  right: 0;
  left: initial;
  background-position-x: right;
}

.hero__image:hover {
  scale: .9;
}

.hero__image:hover img {
  margin-top: -2rem;
  margin-left: -2rem;
  scale: 1.2;
}

.hero__image:hover:before {
  margin-bottom: -2rem;
  margin-left: 2rem;
}

.hero__image:hover:after {
  margin-bottom: -2rem;
  margin-right: -2rem;
}

@keyframes torusAnimate {
  0% {
    top: .5rem;
    bottom: 0;
  }

  50% {
    top: 2rem;
    bottom: 12.5rem;
  }

  100% {
    top: .5rem;
    bottom: 0;
  }
}

@keyframes starAnimate {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0);
  }
}

.blob {
  background: var(--secondary);
  opacity: .1;
  filter: blur(4rem);
  z-index: -1;
  border-radius: 100%;
  position: absolute;
}

.blob--1 {
  width: 50rem;
  height: 50rem;
  animation-name: blob1Animation;
  animation-duration: 30s;
  animation-timing-function: cubic-bezier(.47, 0, .745, .715);
  animation-iteration-count: infinite;
  animation-delay: 5s;
  top: -20rem;
  left: -10rem;
}

.blob--2 {
  width: 60rem;
  height: 60rem;
  animation-name: blob2Animation;
  animation-duration: 20s;
  animation-timing-function: cubic-bezier(.895, .03, .685, .22);
  animation-iteration-count: infinite;
  top: -20rem;
  right: -10rem;
}

@keyframes blob1Animation {
  0% {
    filter: blur(4rem);
    width: 50rem;
    height: 50rem;
    top: -20rem;
    left: -10rem;
  }

  50% {
    filter: blur(8rem);
    width: 60rem;
    height: 60rem;
    top: 0;
    left: -5rem;
  }

  100% {
    filter: blur(4rem);
    width: 50rem;
    height: 50rem;
    top: -20rem;
    left: -10rem;
  }
}

@keyframes blob2Animation {
  0% {
    filter: blur(4rem);
    width: 60rem;
    height: 60rem;
    top: -20rem;
    right: -10rem;
  }

  50% {
    filter: blur(8rem);
    width: 40rem;
    height: 40rem;
    top: 20rem;
    right: 0;
  }

  100% {
    filter: blur(4rem);
    width: 60rem;
    height: 60rem;
    top: -20rem;
    right: -10rem;
  }
}

.how {
  flex-direction: column;
  display: flex;
}

.how__top, .how__bottom {
  width: 100vw;
  height: auto;
}

.how__top {
  margin-bottom: -4rem;
}

@media screen and (width <= 991px) {
  .how__top {
    margin-bottom: -2px;
  }
}

.how__wrapper {
  background-color: var(--secondary);
}

.how__content {
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 0 6rem;
  display: flex;
}

.how__step {
  align-items: center;
  gap: 6rem;
  display: flex;
}

@media screen and (width <= 991px) {
  .how__step {
    flex-direction: column;
    padding: 2rem 0;
  }
}

.how__step__card {
  pointer-events: none;
  align-items: center;
  width: 32rem;
  height: 24rem;
  display: flex;
  position: relative;
}

@media screen and (width <= 991px) {
  .how__step__card {
    display: none;
  }
}

.how__step__content {
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  display: flex;
}

.how__step__pill {
  text-transform: uppercase;
  font-weight: var(--fw-bold);
  color: var(--white);
  background-color: var(--gray900);
  border-radius: 2rem;
  place-content: center;
  height: 2rem;
  padding: 0 1rem;
  font-size: .875rem;
  display: grid;
}

.how__step--1 .card {
  cursor: default;
  transition: box-shadow .5s ease-out, transform .5s ease-out;
  transform: perspective(80rem)rotateX(-20deg)rotateY(30deg)rotateZ(-4deg)scale(.9);
  box-shadow: -1rem 1rem 1rem .25rem #00000040;
}

.how__step--1--animated .card {
  animation-name: step1CardAnimate;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}

.how__step--1--transition .card {
  transform: perspective(60rem)rotateX(0)rotateY(0)rotateZ(0)scale(1);
  box-shadow: 0 0 3rem 1rem #00000040;
}

.how__step--2 .card {
  cursor: default;
  transition: margin .5s ease-out, box-shadow .5s ease-out;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  position: absolute;
  transform: perspective(80rem)rotateX(10deg)rotateY(20deg)rotateZ(-10deg);
}

.how__step--2 .card:first-child {
  z-index: 1;
  animation-name: step2Card1Animate;
  transform: perspective(80rem)rotateX(10deg)rotateY(20deg)rotateZ(-8deg)scale(.6);
  box-shadow: -.25rem .25rem .25rem .25rem #00000080;
}

.how__step--2 .card:nth-child(2) {
  z-index: 2;
  background-color: var(--gray700);
  animation-name: step2Card2Animate;
  transform: perspective(80rem)rotateX(10deg)rotateY(20deg)rotateZ(-6deg)scale(.8)translate(8rem, -1rem);
  box-shadow: -.5rem .5rem .5rem .5rem #00000040;
}

.how__step--2 .card:nth-child(3) {
  z-index: 3;
  background-color: var(--gray600);
  animation-name: step2Card3Animate;
  transform: perspective(80rem)rotateX(10deg)rotateY(20deg)rotateZ(-4deg)scale(1)translate(16rem, -1rem);
  box-shadow: -1rem 1rem 1rem 1rem #00000026;
}

.how__step--2:hover .card:first-child {
  margin-left: -4rem;
  box-shadow: 0 0 .25rem .25rem #00000080;
}

.how__step--2:hover .card:nth-child(2) {
  margin-top: 2rem;
  box-shadow: 0 0 .5rem .5rem #0006;
}

.how__step--2:hover .card:nth-child(3) {
  margin-top: 4rem;
  margin-left: 4rem;
  box-shadow: 0 0 1rem 1rem #0000004d;
}

.how__step--2 .how__step__card {
  width: 120rem;
}

.how__step--3 p {
  margin-bottom: 2rem;
}

@media screen and (width <= 991px) {
  .how__step--3 a {
    width: 100%;
  }
}

.how__step__lobby {
  --transition: .5s ease-out;
  background-image: url("discord_lobby.7658cafe.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20rem 20rem;
  width: 28rem;
  height: 28rem;
  position: relative;
}

.how__step__lobby .avatar {
  transition: var(--transition) margin, var(--transition) box-shadow;
  position: absolute;
}

.how__step__lobby .avatar:first-child {
  margin-top: -2rem;
  margin-left: -1rem;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.how__step__lobby .avatar:first-child .avatar__image {
  background-color: var(--gray900);
  opacity: .9;
  position: relative;
}

.how__step__lobby .avatar:first-child .avatar__image:before {
  content: "+";
  color: var(--gray400);
  font-size: 6rem;
  line-height: 6rem;
  font-weight: var(--fw-thin);
  z-index: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: absolute;
}

.how__step__lobby .avatar:first-child .avatar__image img {
  z-index: 1;
  opacity: 0;
  position: relative;
}

.how__step__lobby .avatar:first-child .avatar__image, .how__step__lobby .avatar:first-child img {
  transition: opacity 1s ease-out;
}

.how__step__lobby .avatar:nth-child(2) {
  margin-top: 2rem;
  margin-left: 1rem;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.how__step__lobby .avatar:nth-child(3) {
  margin-top: 1rem;
  margin-left: -2rem;
  left: 50%;
  transform: translateX(-50%);
}

.how__step__lobby .avatar:nth-child(4) {
  margin-bottom: 1rem;
  margin-left: 2rem;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.how__step--3:hover .how__step__lobby .avatar {
  margin: 0;
}

.how__step--3:hover .how__step__lobby .avatar:first-child {
  border-color: var(--green);
  box-shadow: 0 0 0 3px var(--green);
}

.how__step--3:hover .how__step__lobby .avatar:first-child .avatar__image, .how__step--3:hover .how__step__lobby .avatar:first-child .avatar__image img {
  opacity: 1;
}

.how__step--3:hover .how__step__lobby .avatar:nth-child(2) {
  border-color: var(--tint);
  box-shadow: 0 0 0 3px var(--tint);
}

.how__step--3:hover .how__step__lobby .avatar:nth-child(3) {
  border-color: var(--yellow);
  box-shadow: 0 0 0 3px var(--yellow);
}

.how__step--3:hover .how__step__lobby .avatar:nth-child(4) {
  border-color: var(--primary);
  box-shadow: 0 0 0 3px var(--primary);
}

@keyframes step1CardAnimate {
  0% {
    transform: perspective(80rem)rotateX(-20deg)rotateY(30deg)rotateZ(-4deg)scale(.9);
  }

  50% {
    transform: perspective(80rem)rotateX(-10deg)rotateY(20deg)rotateZ(0)scale(.9);
  }

  100% {
    transform: perspective(80rem)rotateX(-20deg)rotateY(30deg)rotateZ(-4deg)scale(.9);
  }
}

@keyframes step2Card1Animate {
  0% {
    transform: perspective(80rem)rotateX(10deg)rotateY(20deg)rotateZ(-8deg)scale(.6);
  }

  50% {
    transform: perspective(80rem)rotateX(5deg)rotateY(15deg)rotateZ(0)scale(.6);
  }

  100% {
    transform: perspective(80rem)rotateX(10deg)rotateY(20deg)rotateZ(-8deg)scale(.6);
  }
}

@keyframes step2Card2Animate {
  0% {
    transform: perspective(80rem)rotateX(10deg)rotateY(20deg)rotateZ(-6deg)scale(.8)translate(8rem, -1rem);
  }

  50% {
    transform: perspective(80rem)rotateX(5deg)rotateY(15deg)rotateZ(0)scale(.8)translate(8rem, -1rem);
  }

  100% {
    transform: perspective(80rem)rotateX(10deg)rotateY(20deg)rotateZ(-6deg)scale(.8)translate(8rem, -1rem);
  }
}

@keyframes step2Card3Animate {
  0% {
    transform: perspective(80rem)rotateX(10deg)rotateY(20deg)rotateZ(-4deg)scale(1)translate(16rem, -1rem);
  }

  50% {
    transform: perspective(80rem)rotateX(5deg)rotateY(15deg)rotateZ(0)scale(1)translate(16rem, -1rem);
  }

  100% {
    transform: perspective(80rem)rotateX(10deg)rotateY(20deg)rotateZ(-4deg)scale(1)translate(16rem, -1rem);
  }
}

.reputation-section {
  align-items: center;
  gap: 4rem;
  padding: 10rem 0;
  display: flex;
}

@media screen and (width <= 991px) {
  .reputation-section {
    flex-direction: column-reverse;
    padding: 5.5rem 0 2rem;
  }

  .reputation-section__wrapper {
    margin-top: -1.5rem;
  }
}

.reputation-section__content {
  flex-direction: column;
  gap: 2rem;
  display: flex;
}

.reputation-section__image {
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;
  width: 34rem;
  height: 34rem;
  display: flex;
  position: relative;
}

@media screen and (width <= 1440px) {
  .reputation-section__image {
    flex: auto;
  }
}

.reputation-section__image:before {
  content: "";
  background-image: url("hexagon.6036c4c2.png");
  background-repeat: no-repeat;
  background-size: contain;
  transition: scale 4s ease-out;
  animation-name: hexAnimate;
  animation-duration: 8s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  display: block;
  position: absolute;
  inset: 0;
}

@media screen and (width <= 1440px) {
  .reputation-section__image:before {
    top: 50%;
    translate: 0 -50%;
  }
}

@media screen and (width <= 991px) {
  .reputation-section__image:before {
    inset: 0;
    translate: 0;
  }
}

.reputation-section__image img {
  z-index: 1;
  animation-name: diamondAnimate;
  animation-duration: 16s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  position: relative;
}

.reputation-section__scores {
  flex-direction: column;
  gap: 3rem;
  margin-top: 4rem;
  display: flex;
}

.reputation-section__scores__top {
  align-items: center;
  gap: 1rem;
  display: flex;
}

.reputation-section__scores__score {
  --reputation-color: var(--green);
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.reputation-section__scores__score--red {
  --reputation-color: var(--primary);
}

.reputation-section__scores__score--tint {
  --reputation-color: var(--tint);
}

.reputation-section__scores__score svg {
  width: 4rem;
  height: auto;
}

.reputation-section__scores__score h4 {
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: var(--fw-semi);
  flex-direction: column;
  line-height: 2.5rem;
  display: flex;
}

.reputation-section__scores__score h4 span {
  font-size: 1.5rem;
  font-weight: var(--fw-medium);
  color: var(--reputation-color);
}

.reputation-section:hover .reputation-section__image:before {
  scale: 1.1;
}

@keyframes hexAnimate {
  0% {
    opacity: 1;
    transform: rotate(0);
  }

  50% {
    opacity: .5;
    transform: rotate(10deg);
  }

  100% {
    opacity: 1;
    transform: rotate(0);
  }
}

@keyframes diamondAnimate {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(0);
  }
}

.cta {
  --cta-rotation: -5;
  transition: var(--transition) background-color;
  background-color: var(--gray800);
  margin: 4rem 0;
  padding: 4rem 0;
  position: relative;
}

@media screen and (width <= 991px) {
  .cta {
    overflow: hidden;
  }
}

.cta__content {
  text-align: center;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  display: flex;
  position: relative;
}

.cta:after {
  content: "";
  background-color: var(--gray700);
  transform: rotate(var(--cta-rotation));
  z-index: 1;
  transition: var(--transition) background-color;
  margin-left: -150px;
  margin-right: -150px;
  display: block;
  position: absolute;
  inset: 0;
}

.cta .button {
  margin-top: 1rem;
}

.cta--active {
  background-color: var(--gray700);
}

.cta--active:after {
  background-color: var(--secondary);
}

.mentorship {
  grid-template-columns: 2fr minmax(0, 1fr);
  padding: 6rem 0;
  display: grid;
}

@media screen and (width <= 991px) {
  .mentorship {
    display: flex;
  }
}

.mentorship__content {
  flex-direction: column;
  gap: 2rem;
  display: flex;
}

.mentorship__grid {
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  display: grid;
}

@media screen and (width <= 991px) {
  .mentorship__grid {
    flex-direction: column;
    display: flex;
  }
}

.mentorship__item {
  border: 1px solid var(--gray600);
  transition: var(--transition) background-color;
  border-radius: 1rem;
  flex-direction: column;
  gap: .5rem;
  padding: 2rem;
  display: flex;
}

.mentorship__item:hover {
  background-color: var(--gray600);
}

.mentorship__item h4 {
  font-size: 1.5rem;
  font-weight: var(--fw-semi);
  text-transform: uppercase;
}

.mentorship__item p {
  font-weight: var(--fw-light);
}

.mentorship__image {
  place-content: center;
  display: grid;
  position: relative;
}

@media screen and (width <= 991px) {
  .mentorship__image {
    display: none;
  }
}

.mentorship__image > div img {
  z-index: 2;
  position: relative;
}

.mentorship__image > div:before {
  content: "";
  filter: blur(2rem);
  z-index: 1;
  opacity: .15;
  transition: var(--transition) opacity, var(--transition) filter;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  position: absolute;
  inset: 0;
}

.mentorship__image:hover > div:before {
  filter: blur(1rem);
  opacity: .5;
}

.mentorship__image__red-caret {
  z-index: 5;
  width: 450px;
  margin-right: -50px;
  animation-name: redCaretAnimate;
  animation-duration: 16s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  position: absolute;
  top: 150px;
  right: 0;
}

.mentorship__image__red-caret:before {
  background-image: url("red-caret.20fe9082.png");
}

.mentorship__image__blue-caret {
  z-index: 3;
  width: 350px;
  animation-name: blueCaretAnimate;
  animation-duration: 12s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  position: absolute;
  top: 400px;
  right: 0;
}

.mentorship__image__blue-caret:before {
  background-image: url("blue-caret.d202e28d.png");
}

.mentorship__cta {
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  display: flex;
}

@media screen and (width <= 991px) {
  .mentorship__cta .button {
    width: 100%;
  }
}

@keyframes redCaretAnimate {
  0% {
    transform: rotate(0)translateY(0);
  }

  50% {
    transform: rotate(-5deg)translateY(1rem);
  }

  100% {
    transform: rotate(0)translateY(0);
  }
}

@keyframes blueCaretAnimate {
  0% {
    transform: rotate(0)translateY(0);
  }

  50% {
    transform: rotate(5deg)translateY(3rem);
  }

  100% {
    transform: rotate(0)translateY(0);
  }
}

.footer {
  background-color: var(--gray800);
  border-top: 1px solid var(--gray600);
  align-items: center;
  height: 6rem;
  margin-top: 10rem;
  display: flex;
}

.footer__content {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer__content svg {
  max-height: 2.5rem;
}

@media screen and (width <= 991px) {
  .modal {
    width: 100vw;
    height: 100dvh;
  }
}

.modal__backdrop {
  z-index: 200;
  opacity: 0;
  visibility: hidden;
  transition: var(--transition) visibility, var(--transition) opacity;
  background-color: #0b0d19e6;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.modal__close {
  cursor: pointer;
  transition: var(--transition) background-color, var(--transition) color;
  color: var(--white);
  z-index: 10;
  background-color: #0000;
  border: 0;
  border-radius: 4rem;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  margin-top: -650px;
  display: flex;
  position: absolute;
}

@media screen and (width <= 991px) {
  .modal__close {
    margin: 0;
    top: 1rem;
  }
}

.modal__close:hover {
  background-color: var(--gray600);
}

.modal__content {
  text-align: center;
  background-color: var(--gray900);
  box-shadow: 0 0 0 0 var(--secondary);
  border-radius: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 50rem;
  max-width: 100vw;
  padding: 4rem;
  transition: box-shadow .5s ease-out .125s;
  display: flex;
  position: relative;
  overflow: hidden;
}

.modal__content:after {
  content: "";
  background-color: var(--secondary);
  box-shadow: 0 0 0 0 var(--secondary);
  border-radius: 100%;
  width: 1000px;
  height: 500px;
  transition: transform .5s ease-out .125s, box-shadow .5s ease-out .25s;
  display: block;
  position: absolute;
  top: -500px;
  transform: translateY(0)scale(0);
}

@media screen and (width <= 991px) {
  .modal__content {
    border-radius: 0;
    width: 100vw;
    height: 100dvh;
  }

  .modal__content:after {
    width: 200vh;
    height: 100vh;
    top: -75vh;
  }
}

.modal__content h2 {
  font-size: 3rem;
}

.modal__content input[type="text"] {
  background-color: var(--gray900);
  border: 3px solid var(--secondary);
  color: var(--white);
  box-shadow: 0 0 0 0 var(--secondary);
  transition: var(--transition) box-shadow, var(--transition) border;
  border-radius: 1rem;
  width: 20rem;
  height: 4rem;
  padding: 0 1rem;
}

.modal__content input[type="text"]:focus {
  box-shadow: 0 0 2rem 0 var(--secondary);
  outline: 0;
}

.modal__header, .modal__form {
  z-index: 2;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  display: flex;
  position: relative;
}

.modal__error {
  background: var(--gray700);
  font-size: .875rem;
  font-weight: var(--fw-medium);
  border: 3px solid var(--primary);
  opacity: 0;
  transition: var(--transition) height, var(--transition) opacity;
  border-top: 0;
  border-radius: 0 0 .5rem .5rem;
  justify-content: center;
  align-items: center;
  height: 0;
  margin-top: -1rem;
  display: flex;
}

.modal--active .modal__backdrop {
  opacity: 1;
  visibility: visible;
}

.modal--active .modal__content {
  box-shadow: 0 0 24rem 0 var(--secondary);
}

.modal--active .modal__content:after {
  box-shadow: 0 0 24rem 0 var(--secondary);
  transform: translateY(260px)scale(1);
}

.modal--success .modal__content:after {
  transform: translateY(-26rem)scale(3.75);
}

.modal--error .modal__content input[type="text"] {
  border-color: var(--primary);
  border-bottom: 0;
  border-radius: .5rem .5rem 0 0;
}

.modal--error .modal__error {
  opacity: 1;
  width: 20rem;
  height: 2.5rem;
}

.modal .spinner {
  transform-origin: center;
  animation: .75s linear infinite spinner;
}

.modal__last {
  color: var(--gray400);
}

.modal__last p {
  font-size: .875rem;
}

.modal .button {
  transition: var(--transition) width;
  text-wrap: nowrap;
  align-self: center;
  width: 20rem;
  overflow: hidden;
}

.modal .button svg {
  display: none;
}

.modal .button--loading {
  background-color: var(--gray900);
  color: var(--primary);
  width: 4rem;
  padding: 0;
}

.modal .button--loading:hover {
  background-color: var(--gray900);
  color: var(--primary);
}

.modal .button--loading svg {
  display: block;
}

.modal .button--loading span {
  display: none;
}

@keyframes spinner {
  100% {
    transform: rotate(360deg);
  }
}

.button {
  --button-color: var(--primary);
  --button-text: var(--white);
  --button-size: 3rem;
  --button-fs: 1.125rem;
  --button-padding: 1.5rem;
  font-size: var(--button-fs);
  height: var(--button-size);
  border-radius: var(--button-size);
  padding: 0 var(--button-padding);
  color: var(--button-text);
  background-color: var(--button-color);
  text-transform: uppercase;
  font-weight: var(--fw-semi);
  transition: var(--transition) background-color, var(--transition) box-shadow;
  cursor: pointer;
  border: 2px solid #0000;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
  box-shadow: 0 0 #0b0d1980;
}

.button:hover {
  background-color: #ff337b;
  box-shadow: 0 .125rem .25rem #0b0d19e6;
}

.button--outline {
  border-color: var(--button-color);
  background-color: #0000;
}

.button--outline:after {
  opacity: .1;
}

.button--outline:hover {
  background-color: var(--button-color);
}

.button--secondary {
  --button-color: var(--secondary);
}

.button--white {
  --button-color: var(--white);
  --button-text: var(--gray900);
}

.button--large {
  --button-size: 4rem;
  --button-fs: 1.5rem;
  --button-padding: 2.5rem;
}

.button--white.button--outline {
  --button-text: var(--white);
}

.button--white.button--outline:hover {
  --button-text: var(--gray900);
}

.card {
  background-color: var(--gray900);
  border-radius: 1rem;
  flex-direction: column;
  gap: 1.5rem;
  width: 24rem;
  padding: 1.5rem;
  display: inline-flex;
}

.card__main {
  align-items: center;
  gap: 1rem;
  display: flex;
}

.card__details {
  flex-direction: column;
  justify-content: space-between;
  padding: .25rem 0;
  display: flex;
}

.card__name {
  font-size: 2rem;
  font-weight: var(--fw-semi);
}

.card__preferences {
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  display: flex;
}

.card__preference {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

.card__preference__title {
  font-size: .875rem;
  font-weight: var(--fw-medium);
}

.card__preference__items {
  gap: .5rem;
  display: flex;
}

.card__preference__item {
  border-radius: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
}

.card__actions {
  gap: 1rem;
  display: flex;
}

.avatar {
  border: 4px solid var(--gray500);
  border-radius: 100%;
  width: 8rem;
  height: 8rem;
  padding: .125rem;
}

.avatar__image {
  border: 2px solid var(--gray700);
  border-radius: 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.reputation {
  --reputation-bar-height: .625rem;
  text-transform: uppercase;
  color: var(--gray300);
  font-size: .875rem;
  font-weight: var(--fw-medium);
  flex-direction: column;
  align-items: flex-start;
  gap: .25rem;
  display: flex;
}

.reputation__bar {
  background-color: var(--gray700);
  border-radius: var(--reputation-bar-height);
  gap: .25rem;
  width: 12rem;
  display: flex;
  overflow: hidden;
}

.reputation__bar:before {
  content: "";
  height: var(--reputation-bar-height);
  border-radius: var(--reputation-bar-height);
  background-color: var(--green);
  background-image: linear-gradient(90deg, var(--secondary) 0%, var(--green) 100%);
  width: 10rem;
  display: block;
}
/*# sourceMappingURL=index.f2cd2925.css.map */
