.footer {
  margin-top: 10rem;
  background-color: var(--gray800);
  border-top: 1px solid var(--gray600);
  height: 6rem;
  display: flex;
  align-items: center;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      max-height: 2.5rem;
    }
  }
}