a {
  text-decoration: none;
}

h1, h2, h3 {
  font-weight: var(--fw-bold);
  text-transform: uppercase;
  line-height: 1;
}

h1, h2 {
  font-size: 4.5rem;
  
  @media screen and (width <= 991) {
    font-size: 3.5rem;
  }
}

h3 {
  font-size: 2rem;
}

p {
  font-size: 1.25rem;
}

p.lead {
  font-size: 2rem;
  font-weight: var(--fw-semi);
  text-transform: uppercase;
  color: var(--yellow);
  
  @media screen and (width <= 991) {
    font-size: 1.5rem;
  }
}
