.cta {
  margin: 4rem 0;
  padding: 4rem 0;
  position: relative;
  --cta-rotation: -5;
  transition: var(--transition) background-color;
  background-color: var(--gray800);

  @media screen and (width <= 991) {
    overflow: hidden;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1rem;
    position: relative;
    z-index: 2;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    background-color: var(--gray700);
    transform: rotate(var(--cta-rotation));
    inset: 0;
    z-index: 1;
    margin-left: -150px;
    margin-right: -150px;
    transition: var(--transition) background-color;
  }

  .button {
    margin-top: 1rem;
  }

  &--active {
    background-color: var(--gray700);
    
    &::after {
      background-color: var(--secondary);
    }
  }
}