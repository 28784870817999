.avatar {
  width: 8rem;
  height: 8rem;
  border-radius: 100%;
  border: 4px solid var(--gray500);
  padding: 0.125rem;

  &__image {
    width: 100%;
    height: 100%;
    border: 2px solid var(--gray700);
    border-radius: 100%;
    overflow: hidden;
  }
}
