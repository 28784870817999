.card {
  background-color: var(--gray900);
  display: inline-flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
  border-radius: 1rem;
  width: 24rem;

  &__main {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.25rem 0;
  }

  &__name {
    font-size: 2rem;
    font-weight: var(--fw-semi);
  }

  &__preferences {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
  }
  
  &__preference {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &__title {
      font-size: 0.875rem;
      font-weight: var(--fw-medium);
    }

    &__items {
      display: flex;
      gap: 0.5rem;
    }

    &__item {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 2.5rem;
    }
  }

  &__actions {
    display: flex;
    gap: 1rem;
  }
}
