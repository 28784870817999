.blob {
  position: absolute;
  background: var(--secondary);
  opacity: 0.1;
  filter: blur(4rem);
  border-radius: 100%;
  z-index: -1;
  
  &--1 {
    width: 50rem;
    height: 50rem;
    left: -10rem;
    top: -20rem;
    animation-name: blob1Animation;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    animation-delay: 5s;
  }

  &--2 {
    width: 60rem;
    height: 60rem;
    right: -10rem;
    top: -20rem;
    animation-name: blob2Animation;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  }
}

@keyframes blob1Animation {
  0%   {
    left: -10rem;
    top: -20rem;
    width: 50rem;
    height: 50rem;
    filter: blur(4rem);
  }
  50%  {
    left: -5rem;
    top: 0rem;
    width: 60rem;
    height: 60rem;
    filter: blur(8rem);
  }
  100%   {
    left: -10rem;
    top: -20rem;
    width: 50rem;
    height: 50rem;
    filter: blur(4rem);
  }
}

@keyframes blob2Animation {
  0%   {
    width: 60rem;
    height: 60rem;
    right: -10rem;
    top: -20rem;
    filter: blur(4rem);
  }
  50%  {
    width: 40rem;
    height: 40rem;
    right: 0rem;
    top: 20rem;
    filter: blur(8rem);
  }
  100%   {
    width: 60rem;
    height: 60rem;
    right: -10rem;
    top: -20rem;
    filter: blur(4rem);
  }
}