.mentorship {
  padding: 6rem 0;
  display: grid;
  grid-template-columns: 2fr minmax(0, 1fr);

  
  @media screen and (width <= 991) {
    display: flex;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    @media screen and (width <= 991) {
      display: flex;
      flex-direction: column;
    }
  }

  &__item {
    border: 1px solid var(--gray600);
    border-radius: 1rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    transition: var(--transition) background-color;

    &:hover {
      background-color: var(--gray600);
    }

    h4 {
      font-size: 1.5rem;
      font-weight: var(--fw-semi);
      text-transform: uppercase;
    }
    p {
      font-weight: var(--fw-light);
    }
  }

  &__image {
    position: relative;
    display: grid;
    place-content: center;
    @media screen and (width <= 991) {
      display: none;
    }

    > div {
      img {
        position: relative;
        z-index: 2;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        inset: 0;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        filter: blur(2rem);
        z-index: 1;
        opacity: 0.15;
        transition: var(--transition) opacity, var(--transition) filter;
      }
    }
    

    &:hover {
      > div {
        &::before {
          filter: blur(1rem);
          opacity: 0.5;
        }
      }
    }
    &__red-caret {
      position: absolute;
      right: 0;
      margin-right: -50px;
      width: 450px;
      top: 150px;
      animation-name: redCaretAnimate;
      animation-duration: 16s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      z-index: 5;
      &::before {
        background-image: url('../assets/red-caret.png');
      }
    }
    &__blue-caret {
      position: absolute;
      top: 400px;
      right: 0;
      width: 350px;
      z-index: 3;
      animation-name: blueCaretAnimate;
      animation-duration: 12s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      &::before {
        background-image: url('../assets/blue-caret.png');
      }
    }
  }

  &__cta {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    
    @media screen and (width <= 991) {
      .button {
        width: 100%;
      }
    }
  }
}

@keyframes redCaretAnimate {
  0%   {transform: rotate(0deg) translateY(0);}
  50%  {transform: rotate(-5deg) translateY(1rem);}
  100%   {transform: rotate(0deg) translateY(0);}
}

@keyframes blueCaretAnimate {
  0%   {transform: rotate(0deg) translateY(0);}
  50%  {transform: rotate(5deg) translateY(3rem);}
  100%   {transform: rotate(0deg) translateY(0);}
}