.hero {
  display: flex;
  align-items: center;
  height: calc(100vh - 10rem);

  @media screen and (width <= 991) {
    flex-direction: column;
    height: 100vh;
    padding: 6rem 0 2rem;
    justify-content: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 50rem;
    flex: 0 1 auto;
  }

  &__description {
    margin-top: 2rem;
    color: var(--gray50);
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 4rem;
    
    @media screen and (width <= 991) {
      flex-direction: column;
      align-items: stretch;

      .button--outline {
        display: none;
      }
    }
  }

  &__image {
    position: relative;
    padding: 5rem;
    aspect-ratio: 1;
    transition: .5s ease-in-out scale;
    @media screen and (width <= 991) {
      display: none;
    }

    img {
      position: relative;
      z-index: 1;
      animation-name: starAnimate;
      animation-duration: 5s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-delay: 0.75s;
      transition: .5s ease-in-out margin, .5s ease-in-out scale;
    }

    &::before, &::after {
      content: ' ';
      display: block;
      position: absolute;
      inset: 0;
      background-image: url(../assets/torus.png);
      background-repeat: no-repeat;
      background-size: 200% 100%;
      opacity: 0.9;
      width: 50%;
      height: 100%;
      animation-name: torusAnimate;
      animation-duration: 5s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      transition: .5s ease-in-out margin;
    }

    &::before {
      z-index: 0;
    }

    &::after {
      z-index: 2;
      right: 0;
      left: initial;
      background-position-x: right;
    }
    
    &:hover {
      scale: 0.9;
      img {
        margin-left: -2rem;
        margin-top: -2rem;
        scale: 1.2;
      }
      &::before {
        margin-left: 2rem;
        margin-bottom: -2rem;
      }
      &::after {
        margin-right: -2rem;
        margin-bottom: -2rem;
      }
    }
  }
}

@keyframes torusAnimate {
  0%   {bottom: 0px; top: 0.5rem;}
  50%  {bottom: 12.5rem; top: 2rem;}
  100%   {bottom: 0px; top: 0.5rem;}
}

@keyframes starAnimate {
  0%   {transform: rotate(0deg);}
  50%  {transform: rotate(-5deg);}
  100%   {transform: rotate(0deg);}
}