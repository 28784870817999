.how {
  display: flex;
  flex-direction: column;
  
  &__top, &__bottom {
    width: 100vw;
    height: auto;
  }

  &__top {
    margin-bottom: -4rem;
    
    @media screen and (width <= 991) {
      margin-bottom: -2px;
    }
  }

  &__wrapper {
    background-color: var(--secondary);
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 0 6rem;
  }

  &__step {
    display: flex;
    align-items: center;
    gap: 6rem;
    
    @media screen and (width <= 991) {
      flex-direction: column;
      padding: 2rem 0;
    }

    &__card {
      position: relative;
      width: 32rem;
      height: 24rem;
      display: flex;
      align-items: center;
      pointer-events: none;
      @media screen and (width <= 991) {
        display: none;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }

    &__pill {
      padding: 0 1rem;
      height: 2rem;
      text-transform: uppercase;
      font-weight: var(--fw-bold);
      font-size: 0.875rem;
      color: var(--white);
      background-color: var(--gray900);
      display: grid;
      place-content: center;
      border-radius: 2rem;
    }

    &--1 {
      .card {
        cursor: default;
        transform: perspective(80rem) rotateX(-20deg) rotateY(30deg) rotateZ(-4deg) scale(0.9);
        box-shadow: -1rem 1rem 1rem 0.25rem rgba(0 0 0 / 0.25);
        transition: .5s ease-out box-shadow, .5s ease-out transform;
      }

      &--animated .card {
        animation-name: step1CardAnimate;
        animation-duration: 10s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-fill-mode: forwards;
      }

      &--transition .card {
        transform: perspective(60rem) rotateX(0) rotateY(0) rotateZ(0) scale(1);
        box-shadow: 0 0rem 3rem 1rem rgba(0 0 0 / 0.25);
      }
    }

    &--2 {
      .card {
        position: absolute;
        transform: perspective(80rem) rotateX(10deg) rotateY(20deg) rotateZ(-10deg);
        animation-duration: 10s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-fill-mode: forwards;
        transition: 0.5s ease-out margin, 0.5s ease-out box-shadow;
        cursor: default;

        &:nth-child(1) {
          z-index: 1;
          transform: perspective(80rem) rotateX(10deg) rotateY(20deg) rotateZ(-8deg) scale(0.6);
          box-shadow: -0.25rem 0.25rem 0.25rem 0.25rem rgba(0 0 0 / 0.5);
          animation-name: step2Card1Animate;
        }
        &:nth-child(2) {
          z-index: 2;
          transform: perspective(80rem) rotateX(10deg) rotateY(20deg) rotateZ(-6deg) scale(0.8) translate(8rem, -1rem);
          background-color: var(--gray700);
          box-shadow: -0.5rem 0.5rem 0.5rem 0.5rem rgba(0 0 0 / 0.25);
          animation-name: step2Card2Animate;
        }
        &:nth-child(3) {
          z-index: 3;
          transform: perspective(80rem) rotateX(10deg) rotateY(20deg) rotateZ(-4deg) scale(1) translate(16rem, -1rem);
          background-color: var(--gray600);
          box-shadow: -1rem 1rem 1rem 1rem rgba(0 0 0 / 0.15);
          animation-name: step2Card3Animate;
        }
      }
      &:hover {
        .card {
          &:nth-child(1) {
            margin-left: -4rem;
            box-shadow: 0rem 0rem 0.25rem 0.25rem rgba(0 0 0 / 0.5);
          }
          &:nth-child(2) {
            margin-top: 2rem;
            box-shadow: 0rem 0rem 0.5rem 0.5rem rgba(0 0 0 / 0.4);
          }
          &:nth-child(3) {
            margin-left: 4rem;
            margin-top: 4rem;
            box-shadow: 0rem 0rem 1rem 1rem rgba(0 0 0 / 0.3);
          }
        }
      }
    }
    
    &--2 &__card {
      width: 120rem;
    }

    &--3 {
      p {
        margin-bottom: 2rem;
      }
    
      @media screen and (width <= 991) {
        a {
          width: 100%;
        }
      }
    }

    &__lobby {
      --transition: 0.5s ease-out;

      width: 28rem;
      height: 28rem;
      position: relative;
      background-image: url(../assets/discord_lobby.webp);
      background-size: 20rem 20rem;
      background-repeat: no-repeat;
      background-position: center center;

      .avatar {
        position: absolute;
        transition: var(--transition) margin, var(--transition) box-shadow;

        &:nth-child(1) {
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          margin-top: -2rem;
          margin-left: -1rem;

          .avatar__image {
            position: relative;
            background-color: var(--gray900);
            opacity: 0.9;
            
            &:before {
              content: '+';
              display: flex;
              align-items: center;
              justify-content: center;
              color: var(--gray400);
              width: 100%;
              font-size: 6rem;
              line-height: 6rem;
              font-weight: var(--fw-thin);
              position: absolute;
              z-index: 0;
            }

            img {
              position: relative;
              z-index: 1;
              opacity: 0;
            }
          }

          .avatar__image, img {
            transition: 1s ease-out opacity;
          }
        }

        &:nth-child(2) {
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          margin-top: 2rem;
          margin-left: 1rem;
        }

        &:nth-child(3) {
          left: 50%;
          transform: translateX(-50%);
          margin-top: 1rem;
          margin-left: -2rem;
        }

        &:nth-child(4) {
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
          margin-bottom: 1rem;
          margin-left: 2rem;
        }
      }
    }

    &--3:hover &__lobby {
      .avatar {
        margin: 0;
        &:nth-child(1) {
          border-color: var(--green);
          box-shadow: 0 0 0 3px var(--green);

          .avatar__image {
            opacity: 1;

            img {
              opacity: 1;
            }
          }
        }
        &:nth-child(2) {
          border-color: var(--tint);
          box-shadow: 0 0 0 3px var(--tint);
        }
        &:nth-child(3) {
          border-color: var(--yellow);
          box-shadow: 0 0 0 3px var(--yellow);
        }
        &:nth-child(4) {
          border-color: var(--primary);
          box-shadow: 0 0 0 3px var(--primary);
        }
      }
    }
  }
}

@keyframes step1CardAnimate {
  0% {transform: perspective(80rem) rotateX(-20deg) rotateY(30deg) rotateZ(-4deg) scale(0.9);}
  50% {transform: perspective(80rem) rotateX(-10deg) rotateY(20deg) rotateZ(0deg) scale(0.9);}
  100% {transform: perspective(80rem) rotateX(-20deg) rotateY(30deg) rotateZ(-4deg) scale(0.9);}
}

@keyframes step2Card1Animate {
  0% {transform: perspective(80rem) rotateX(10deg) rotateY(20deg) rotateZ(-8deg) scale(0.6);}
  50% {transform: perspective(80rem) rotateX(5deg) rotateY(15deg) rotateZ(0deg) scale(0.6);}
  100% {transform: perspective(80rem) rotateX(10deg) rotateY(20deg) rotateZ(-8deg) scale(0.6);}
}
@keyframes step2Card2Animate {
  0% {transform: perspective(80rem) rotateX(10deg) rotateY(20deg) rotateZ(-6deg) scale(0.8) translate(8rem, -1rem);}
  50% {transform: perspective(80rem) rotateX(5deg) rotateY(15deg) rotateZ(0deg) scale(0.8) translate(8rem, -1rem);}
  100% {transform: perspective(80rem) rotateX(10deg) rotateY(20deg) rotateZ(-6deg) scale(0.8) translate(8rem, -1rem);}
}
@keyframes step2Card3Animate {
  0% {transform: perspective(80rem) rotateX(10deg) rotateY(20deg) rotateZ(-4deg) scale(1) translate(16rem, -1rem);}
  50% {transform: perspective(80rem) rotateX(5deg) rotateY(15deg) rotateZ(0deg) scale(1) translate(16rem, -1rem);}
  100% {transform: perspective(80rem) rotateX(10deg) rotateY(20deg) rotateZ(-4deg) scale(1) translate(16rem, -1rem);}
}