.modal {

  @media screen and (width <= 991) {
    width: 100vw;
    height: 100dvh;
  }

  &__backdrop {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    inset: 0;
    background-color: rgba(11 13 25 / 0.9);
    z-index: 200;
    opacity: 0;
    visibility: hidden;
    transition: var(--transition) visibility, var(--transition) opacity;
  }

  &__close {
    border: 0;
    background-color: transparent;
    position: absolute;
    margin-top: -650px;
    cursor: pointer;
    transition: var(--transition) background-color, var(--transition) color;
    color: var(--white);
    border-radius: 4rem;
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;

    @media screen and (width <= 991) {
      margin: 0;
      top: 1rem;
    }

    &:hover {
      background-color: var(--gray600);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 2rem;
    width: 50rem;
    max-width: 100vw;
    padding: 4rem;
    background-color: var(--gray900);
    border-radius: 2rem;
    overflow: hidden;
    position: relative;
    transition: 0.5s ease-out box-shadow 0.125s;
    box-shadow: 0 0 0 0 var(--secondary);

    &::after {
      display: block;
      content: '';
      position: absolute;
      background-color: var(--secondary);
      width: 1000px;
      height: 500px;
      top: -500px;
      transition: 0.5s ease-out transform 0.125s, 0.5s ease-out box-shadow 0.25s;
      transform: translateY(0px) scale(0);
      border-radius: 100%;
      box-shadow: 0 0 0 0 var(--secondary);
    }

    @media screen and (width <= 991) {
      width: 100vw;
      height: 100dvh;
      border-radius: 0;

      &::after {
        width: 200vh;
        height: 100vh;
        top: -75vh
      }
    }

    h2 {
      font-size: 3rem;
    }

    input[type="text"] {
      padding: 0 1rem;
      height: 4rem;
      background-color: var(--gray900);
      border: 3px solid var(--secondary);
      border-radius: 1rem;
      color: var(--white);
      box-shadow: 0 0 0 0 var(--secondary);
      transition: var(--transition) box-shadow, var(--transition) border;
      width: 20rem;

      &:focus {
        outline: 0;
        box-shadow: 0 0 2rem 0 var(--secondary);
      }
    }
  }

  &__header, &__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    z-index: 2;
    align-items: center;
  }

  &__error {
    margin-top: -1rem;
    background: var(--gray700);
    border-radius: 0 0 0.5rem 0.5rem;
    font-size: 0.875rem;
    font-weight: var(--fw-medium);
    border: 3px solid var(--primary);
    border-top: 0;
    height: 0;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: var(--transition) height, var(--transition) opacity;
  }

  &--active &__backdrop {
    opacity: 1;
    visibility: visible;
  }

  &--active &__content {
    box-shadow: 0 0 24rem 0 var(--secondary);

    &::after {
      transform: translateY(260px) scale(1);
      box-shadow: 0 0 24rem 0 var(--secondary);
    }
  }

  &--success &__content {
    &::after {
      transform: translateY(-26rem) scale(3.75);
    }
  }
  
  &--error &__content input[type="text"] {
    border-color: var(--primary);
    border-bottom: 0;
    border-radius: 0.5rem 0.5rem 0 0;
  }
  &--error &__error {
    width: 20rem;
    height: 2.5rem;
    opacity: 1;
  }

  .spinner {
    transform-origin: center;
    animation: spinner .75s infinite linear;
  }

  &__last {
    color: var(--gray400);

    p {
      font-size: 0.875rem;
    }
  }

  .button {
    transition: var(--transition) width;
    align-self: center;
    width: 20rem;
    overflow: hidden;
    text-wrap: nowrap;

    svg {
      display: none;
    }

    &--loading {
      width: 4rem;
      padding: 0;
      background-color: var(--gray900);
      color: var(--primary);

      &:hover {
        background-color: var(--gray900);
        color: var(--primary);
      }

      svg {
        display: block;
      }
      span {
        display: none;
      }
    }
  }
}

@keyframes spinner {
  100% { transform:rotate(360deg); }
}