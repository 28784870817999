.reputation {
  --reputation-bar-height: 0.625rem;

  text-transform: uppercase;
  font-size: 0.875rem;
  color: var(--gray300);
  font-weight: var(--fw-medium);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;

  &__bar {
    display: flex;
    gap: 0.25rem;
    background-color: var(--gray700);
    border-radius: var(--reputation-bar-height);
    overflow: hidden;
    width: 12rem;

    &::before {
      content: '';
      display: block;
      width: 10rem;
      height: var(--reputation-bar-height);
      border-radius: var(--reputation-bar-height);
      background-color: var(--green);
      background-image: linear-gradient(90deg, var(--secondary) 0%, var(--green) 100%);
    }
  }
}
