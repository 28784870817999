html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--gray900);
  color: var(--white);
  font-family: var(--ff);
  margin: 0;
  overflow-x: hidden;
  padding: 0;
}

input, button, select, textarea, optgroup, option {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
}

* {
  box-sizing: border-box;
}

.container {
  width: 90rem;
  max-width: 100vw;
  margin: 0 auto;
  position: relative;
  padding: 0 2rem;
  
  @media screen and (width <= 991) {
    padding: 0 1rem;
    overflow: hidden;
  }
}
