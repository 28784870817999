:root {
  --primary: #FE005A;
  --secondary: #5762D5;
  --yellow: #FFCA64;
  --green: #70E13A;
  --tint: #2FD7EE;
  --gray900: #0B0D19;
  --gray800: #121420;
  --gray700: #191B27;
  --gray600: #2D2F3B;
  --gray500: #41434F;
  --gray400: #5F616D;
  --gray300: #A5A7B3;
  --gray200: #E6E4F5;
  --gray100: #F2F0FC;
  --gray50: #F6F5FC;
  --white: #fff;
  --ff: Saira, Roboto, sans-serif;

  --transition: .3s ease-out;

  --fw-thin: 100;
  --fw-xlight: 200;
  --fw-light: 300;
  --fw-regular: 400;
  --fw-medium: 500;
  --fw-semi: 600;
  --fw-bold: 700;
}
