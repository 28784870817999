.topbar {
  position: fixed;
  width: 100vw;
  z-index: 100;
  padding: 2rem 0;
  top: 0;
  transition: var(--transition) background-color, var(--transition) padding, var(--transition) backdrop-filter;
  backdrop-filter: blur(0);
  
  @media screen and (width <= 991) {
    svg {
      max-height: 3rem;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__buttons {
    display: flex;
    gap: 1rem;
    
    @media screen and (width <= 991) {
      display: none;
    }
  }

  &--sticky {
    background-color: rgba(0 0 0 / 0.15);
    padding: 1rem 0;
    backdrop-filter: blur(1rem);
  }
}
