.reputation-section {
  display: flex;
  padding: 10rem 0;
  gap: 4rem;
  align-items: center;

  @media screen and (width <= 991) {
    flex-direction: column-reverse;
    padding: 5.5rem 0 2rem;

    &__wrapper {
      margin-top: -1.5rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &__image {
    position: relative;
    width: 34rem;
    height: 34rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 0 auto;

    @media screen and (width <= 1440) {
      flex: 1 1 auto;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      background-image: url(../assets/hexagon.png);
      background-repeat: no-repeat;
      background-size: contain;
      animation-name: hexAnimate;
      animation-duration: 8s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      transition: 4s ease-out scale;
      inset: 0;
      @media screen and (width <= 1440) {
        top: 50%;
        translate: 0 -50%;
      }
      @media screen and (width <= 991) {
        inset: 0;
        translate: 0;
      }
    }

    img {
      position: relative;
      z-index: 1;
      animation-name: diamondAnimate;
      animation-duration: 16s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }
  }

  &__scores {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin-top: 4rem;

    &__top {
      display: flex;
      gap: 1rem;
      align-items: center;
    }

    &__score {
      --reputation-color: var(--green);
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &--red {
        --reputation-color: var(--primary);
      }

      &--tint {
        --reputation-color: var(--tint);
      }
      
      svg {
        width: 4rem;
        height: auto;
      }

      h4 {
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: var(--fw-semi);
        display: flex;
        flex-direction: column;
        line-height: 2.5rem;
  
        span {
          font-size: 1.5rem;
          font-weight: var(--fw-medium);
          color: var(--reputation-color);
        }
      }
    }
  }

  &:hover &__image {
    &:before {
      scale: 1.1;
    }
  }
}

@keyframes hexAnimate {
  0%   {opacity: 1; transform: rotate(0deg);}
  50%  {opacity: 0.5; transform: rotate(10deg);}
  100%   {opacity: 1; transform: rotate(0deg);}
}

@keyframes diamondAnimate {
  0%   {transform: rotate(0deg);}
  50%  {transform: rotate(-10deg);}
  100%   {transform: rotate(0deg);}
}