.button {
  --button-color: var(--primary);
  --button-text: var(--white);
  --button-size: 3rem;
  --button-fs: 1.125rem;
  --button-padding: 1.5rem;

  font-size: var(--button-fs);
  height: var(--button-size);
  border-radius: var(--button-size);
  padding: 0 var(--button-padding);
  color: var(--button-text);
  background-color: var(--button-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: var(--fw-semi);
  border: 2px solid transparent;
  position: relative;
  transition: var(--transition) background-color, var(--transition) box-shadow;
  box-shadow: 0 0 0 rgba(11 13 25 / 0.5);
  cursor: pointer;

  &:hover {
    background-color: #FF337B;
    box-shadow: 0 0.125rem 0.25rem rgba(11 13 25 / 0.9);
  }

  &--outline {
    &:after {
      opacity: 0.1;
    }
    background-color: transparent;
    border-color: var(--button-color);

    &:hover {
      background-color: var(--button-color);
    }
  }

  &--secondary {
    --button-color: var(--secondary);
  }

  &--white {
    --button-color: var(--white);
    --button-text: var(--gray900);
  }

  &--large {
    --button-size: 4rem;
    --button-fs: 1.5rem;
    --button-padding: 2.5rem;
  }

  &--white.button--outline {
    --button-text: var(--white);

    &:hover {
      --button-text: var(--gray900);
    }
  }
}
